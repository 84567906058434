import React from "react";
import _ from "lodash";
import boxesContent from "../../assets/OurValuesBoxesContent";
import * as styles from "../../styles/ofertaValuesIcons.module.css";

const OfertaValuesIcons = () => {
  const filteredBoxes = boxesContent.filter((box, index) => index < 3);
  return (
    <section className={styles.sectionValues}>
      {filteredBoxes.map((boxImg, index) => {
        return (
          <div key={_.uniqueId("key_")} className={styles.valuesBox}>
            <div>
              <img aria-label="hidden" src={boxImg.img} />
            </div>
            <div className={styles.desc}>
              <p>{boxImg.up}</p>
              <p>{boxImg.down}</p>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default OfertaValuesIcons;
