import React from "react";
import * as styles from "../../styles/ofertaBoxy.module.css";
import img2 from "../../images/offerSubPage/Salon/Salon1.png";
import img3 from "../../images/offerSubPage/Salon/Salon2.png";
import img4 from "../../images/offerSubPage/Salon/Salon3.png";

const OfertaBoxes = () => {
  return (
    <div className={styles.boxesContainer}>
      <div className={styles.boxesWrapper}>
        <div className={styles.boxImg}>
          <img src={img2} alt="" />
        </div>
        <div className={styles.textWrap}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliqu. Lorem ipsum dolor sit amet, consetetur.
          </p>
        </div>
      </div>
      <div>
        <div className={styles.boxImg}>
          <img src={img3} alt="" />
        </div>
        <div className={styles.textWrap}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliqu. Lorem ipsum dolor sit amet, consetetur.
          </p>
        </div>
      </div>
      <div>
        <div className={styles.boxImg}>
          <img src={img4} alt="" />
        </div>
        <div className={styles.textWrap}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliqu. Lorem ipsum dolor sit amet, consetetur.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfertaBoxes