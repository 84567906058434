import React from "react";
import * as styles from "../../styles/oferta.module.css";
import coverPhoto from "../../images/offerSubPage/Salon/SalonHeader.png";

const OfferHeader = () => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.imgWrapper}>
        <img src={coverPhoto} alt="" />
      </div>

      <div className={styles.titleBox}>
        <h1>Przykładowa nazwa</h1>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliqu
        </p>
      </div>

      <div className={styles.floatingBox}>
        <div className={styles.textWrapper}>
          <div className={styles.bigNumber}>1</div>
          <div className={styles.text}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliq
          </div>
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.bigNumber}>2</div>
          <div className={styles.text}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliq
          </div>
        </div>
        <div className={styles.textWrapper}>
          <div className={styles.bigNumber}>3</div>
          <div className={styles.text}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            dolore magna aliq
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferHeader;
