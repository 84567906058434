import React from "react";
import * as styles from "../../styles/ofertaValues.module.css";

const OfertaValues = () => {
  return (
    <section className="section-background">
      <div className={styles.sectionContainer}>
        <div className={styles.titleWrapper}>
          <h2>Nasze Wartości</h2>
        </div>
        <div className={styles.descWrapper}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. Lorem ipsum dolor sit amet, consetetur
            sadipscing
          </p>
        </div>
      </div>
    </section>
  );
};

export default OfertaValues;
