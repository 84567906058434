import React from "react";
import * as styles from "../styles/oferta.module.css";
import Layout from "../components/Layout";
import OfferHeader from "../components/Oferta/OfferHeader";
import OfferBody from "../components/Oferta/OfferBody";
import OfferCarusele from "../components/OfferCarusele";
import OfferComponent from "../components/OfferComponent";
import ContactUs from "../components/ContactUs";
import GoHome from "../components/GoHome";

const Oferta = () => {
  return (
    <Layout>
      <section className={styles.section}>
        <OfferHeader />
        <OfferBody />
        <OfferComponent />
        <OfferCarusele desktop={4} tablet={2} mobile={1} />
        <GoHome />
        <ContactUs />
      </section>
    </Layout>
  );
};

export default Oferta;
